
import { Vue, Component, Prop } from "vue-property-decorator"
import {
  SurveyAssignment,
  SubmitSurveyResponseMutation,
  SubmitSurveyResponseMutationMutation,
  SurveyQuestion,
} from "@/gql"
import ScaleInput from "@/components/fields/ScaleInput.vue"
import SurveySingleChoiceRadio from "@/components/fields/SurveySingleChoiceRadio.vue"

@Component({
  components: {
    ScaleInput,
    SurveySingleChoiceRadio,
  },
})
export default class SurveyForm extends Vue {
  @Prop({ required: true })
  readonly assignment!: SurveyAssignment

  complete = false
  currentQuestionIndex = 0
  answers: Record<string, any> = {}

  @Prop()
  refetchQueries!: any

  get questions() {
    // Get survey questions
    return this.assignment.survey.questions
  }

  get displayQuestions() {
    return this.assignment.assignmentType == "appraisal_survey_assignment"
      ? this.questions
      : [this.currentQuestion]
  }

  get currentQuestion(): SurveyQuestion | undefined {
    if (this.questions.length) {
      return this.questions[this.currentQuestionIndex]
    } else {
      this.addError("Couldn't find survey questions")
      return undefined
    }
  }

  validateForm() {
    const optionalQuestions = this.questions.filter((q) => q.options.optional).map((x) => x.id)

    const emptyAnswers = this.setupAnswer.filter(
      (a) => !optionalQuestions.includes(a.question_id as string)
    )
    return emptyAnswers.some((a) => !a)
  }

  async submitAppraisal() {
    try {
      const result = await this.$apollo.mutate<SubmitSurveyResponseMutationMutation>({
        mutation: SubmitSurveyResponseMutation,
        variables: {
          id: this.assignment.id,
          answer: this.setupAnswer,
        },
        refetchQueries: this.refetchQueries,
      })

      if (result && result.data) {
        if (result.data.submitSurveyResponse.error) {
          this.addMutationError(result.data.submitSurveyResponse.error)
        } else {
          // Clear questions
          this.questions.length = 0
          this.complete = true

          this.$emit("submit")
        }
      }
    } catch (e) {
      this.addGraphQLError(e as Error)
    }
  }

  showPreviousQuestion() {
    if (this.currentQuestionIndex >= 1) this.currentQuestionIndex--
  }

  showNextQuestion() {
    if (this.currentQuestionIndex < this.questions.length - 1) this.currentQuestionIndex++
  }

  get setupAnswer() {
    let answerHash: any[] = []

    for (const [key, value] of Object.entries(this.answers)) {
      answerHash.push({ question_id: key, choice: value })
    }

    return answerHash
  }
}
